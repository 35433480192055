
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { deleteClaim, updateClaim } from "@/api/claim.api";
import { useRouter } from "vue-router";
import { getDictionary } from "@/api/dictionaries.api";
import Multiselect from "@vueform/multiselect";
import { Claim } from "@/modules/claims/claim.model";
import useVuelidate from "@vuelidate/core";
import { helpers,required } from "@vuelidate/validators";
import { useStore } from "vuex";
import { getPanel } from "@/api/encounter.api";

export interface Data {
  panel: unknown;
  claim?: Claim;
  patient: unknown;
  dict: {
    typeOfFacility: [];
    typeOfCare: [];
    frequency: [];
    admissionType: [];
    admissionSource: [];
    dischargeStatus: [];
    claimStatuses: [];
  };
}

export default defineComponent({
  name: "ClaimComponent",
  props: ["claim", "patient"],
  components: { Multiselect },
  setup(props, ctx) {
    const store = useStore();

    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      panel: { facilities: [], patients: [], providers: [] },
      patient: undefined,
      claim: undefined,
      dict: {
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        admissionType: [],
        admissionSource: [],
        dischargeStatus: [],
        claimStatuses: [],
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.panel = await getPanel();
      await getDict();

      data.dict.claimStatuses = store.getters.allClaimStatuses;

      data.claim = props.claim;
      data.patient = props.patient;
    });

    const claimRules = {
      claim: {
        claimId: { required:helpers.withMessage("Required", required) },
        admissionType: { required:helpers.withMessage("Required", required) },
        admissionSource: { required:helpers.withMessage("Required", required) },
        dischargeStatus: { required:helpers.withMessage("Required", required) },
        documentControlNumber: { required:helpers.withMessage("Required", required) },
        remarksField: { required:helpers.withMessage("Required", required) },
        billingProviderId: { required:helpers.withMessage("Required", required) },
        payerId: { required:helpers.withMessage("Required", required) },
      },
    };

    const v$ = useVuelidate(claimRules, data as never);

    async function getDict() {
      data.dict.typeOfFacility = await getDictionary(4, "");
      data.dict.typeOfCare = await getDictionary(5, "");
      data.dict.frequency = await getDictionary(6, "");
      data.dict.admissionType = await getDictionary(7, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
    }

    async function updateClaimData() {
      await updateClaim(data.claim);
    }

    async function deleteClaimData() {
      await deleteClaim(data.claim);
      ctx.emit("claimDeleted");
    }

    function selectClaim() {
      router.push({
        name: "waystarFieldsPage",
        params: { claimId: data.claim?.id },
      });
    }
    return {
      organizationId,
      data,
      updateClaimData,
      deleteClaimData,
      selectClaim,
      v$,
    };
  },
});
